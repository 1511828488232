@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600&display=swap');

* {
    outline: none;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
}

a,
span {
    font-weight: 400;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.b-shadow {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
    -webkit-appearance: none;
    -webkit-box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
}

input::placeholder {
    color: #c4c4c4;
}

.form-select,
.form-control {
    -webkit-appearance: none !important;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

/* main-content */
.main-content {
    max-width: 375px;
    margin: auto;
    padding: 0 10px;
    height: 100vh;
}

.main-content .images {
    text-align: center;
    // background-color: red;
}

.main-content .images .landing-logo {
    margin-top: 15px;
    margin-bottom: 30px !important;
    width: 100px;
}

.main-content .landing_subscription_form {
    margin-bottom: 20px;
    float: left;
    width: 100%;
}

.main-content .landing_subscription_form .enter-otp {
    font-size: 16px;
    font-weight: 600;
    margin: 15px 0;
}

.main-content .landing_subscription_form .number_taken_inputcol .number_formate_bg {
    float: left;
    width: 15%;
    padding: 14px 0;
    border-radius: 10px;
    color: #262626;
    font-weight: 500;
    font-size: 16px;
    // margin-right: 19px;
    text-align: center;
}

.main-content .landing_subscription_form .number_taken_inputcol input {
    border-radius: 10px;
    // float: left;
    font-size: 16px;
    border: solid 1px transparent;
    width: 80%;
    margin: 0 !important;
    float: right;
    padding: 13px;
    // width: 69%;
    // background-color: red;
}

.main-content .landing_subscription_form .number_taken_inputcol .get-otp {
    width: 100%;
    float: left;
    background-color: #feca14;
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 0px;
    border-radius: 10px;
    border: none;
    -webkit-appearance: button;
}

.main-content .landing_subscription_form .number_taken_inputcol .get-otp:hover {
    background-color: #9c050c;
    color: #fff;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.main-content .landing_subscription_form .number_taken_inputcol p {
    width: 100%;
    float: left;
    font-size: 10px;
    color: #262626;
    text-align: center;
}



.main-content .landing_subscription_form .number_taken_inputcol p br {
    display: none;
}

// .pin-form .enter-otp span {
//     color: #9c050c;
//     font-weight: 600;
// }

// .pin-form form .number_taken_inputcol {
//     display: flex;
//     justify-content: space-between;
//     background-color: red;
// }

// .landing-content .pin-form .number_taken_inputcol {
//     padding: 0 !important;
//     width: 100% !important;
// }
/*privacy policy*/
.links {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: -10px;
}

.links a {
    color: #000;
    text-decoration: none;
    font-size: 13px;
}

.links a:hover {
    color: #c70404;
    transition: .2s;
}

.links span {
    background: #9c050c;
    height: 8px;
    width: 8px;
    display: inline-block;
    border-radius: 50%;
    margin: 0 8px;
}

#p-price-heading {
    margin-top: 10px;
    padding: 0;
    font-size: 0.6rem !important;
    line-height: inherit;
    float: left;
    width: 100%;
    text-align: center;
    // background-color: #c4c4c4;
}

.error-heading {
    color: #9c050c;
}

.Resend-Container {
    padding-top: 50px;
    // background-color: red;
}


.lp-content {
    position: fixed;
    display: flex;
    width: 100%;
    left: 0;
    right: 0;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background: #fff;
}

.lp-content .c-div {
    background-image: url(../Assets/Images/landing-popup-bg.webp);
    background-size: cover;
    min-height: 300px;
    max-width: 300px;
    padding: 35px 15px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.lp-content .title {
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
}

.lp-content .title span {
    color: #c70404;
    font-weight: 600;
}

.lp-content button {
    width: 100%;
    float: left;
    background-color: #ffd400;
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 0px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    text-transform: capitalize;
}

.lp-content button:hover {
    background-color: #c70404;
    color: #fff;
    transition: all .2s ease-in-out;
}

@media screen and (max-width:365px) {
    #any {

        width: 65%;
    }

}

// Subcription css end --------------------------------------------

/*privacy policy*/
// .links{
//     float: left;
//     width: 100%;
//     text-align: center;
// }
// .links a{
//     color: #000;
//     text-decoration: none;
//     font-size: 13px;
// }
// .links a:hover{
//     color: #c70404;
//     transition: .2s;
// }
// .links span{
//     background: #c70404;
//     height: 8px;
//     width: 8px;
//     display: inline-block;
//     border-radius: 50%;
//     margin: 0 8px;
// }
.otp-heading {
    margin-top: 10px;
    padding: 0 !important;

}

.otp-heading {
    text-align: center;
    margin-top: 10px;
    // background-color: red;
}

.pin-form .otp_instructions {
    font-size: 15px;
    font-weight: 500 !important;
    color: #262626 !important;
    // didnt recieve otp heading
}

.pin-form .resend_otp {
    color: #9c050c;
    font-weight: 600;
    font-size: 15px;
    text-decoration: underline !important;
    // background-color: red;
    // resend otp

}

.main-content .landing_subscription_form .otp-form .otp_input {
    display: flex;
    justify-content: space-between;
    // background-color: red;
}

.main-content .landing_subscription_form .otp-form .number_taken_inputcol input {
    border-radius: 10px;
    float: left;
    padding: 15px;
    font-size: 16px;
    border: solid 1px transparent;
    width: 65px;
    height: 50px;
    text-align: center;
    // background-color: #9c050c;
    // input boxes
}

// .p-pricing{
//     margin-top: 10px;
//     font-size: 0.6rem !important;
//     // background-color: red;
// }
// .resend_otp{


// }
.pin-form .resend_otp:hover {
    color: #feca14;
    text-decoration: underline;
    cursor: pointer;
}

.images {
    // background-color: #262626;
    // margin-top: 5px;
}

// .logo{
//     max-height: 80px !important;
//     margin-bottom: 20px !important;
// }
// .main-content{
//     height: auto !important;

// }


.submit-ot {
    width: 100%;
    float: left;
    background-color: #feca14;
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 0px;
    border-radius: 10px;
    border: none;
    // margin-top: -5px;

}

.submit-ot:hover {
    background-color: #9c050c;
    color: #fff;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

#p-price {
    // background-color: #262626;
    margin-top: 75px;
    text-align: center;
}

.timer-span {
    text-decoration: none !important;
    color: #9c050c;
    margin-left: 10px;

    // font-size: 0.9rem;
}


@media screen and (max-width:450px) {
    .otp-heading {

        margin-top: 15px;
    }

    .submit-ot {
        width: 97%;
        font-size: 16px;
        font-weight: 600;
        padding: 15px 0px;
    }


}

@media screen and (max-width:375px) {
    .otp-heading {

        margin-top: 15px;
    }

    .submit-ot {
        width: 97%;
        font-size: 16px;
        font-weight: 600;
        padding: 15px 0px;
    }

    .main-content .landing_subscription_form .otp-form .number_taken_inputcol input {

        border: 1px solid #9c050c;

        // input boxes
    }

}